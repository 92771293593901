<template>
  <div>
    <NewOffers />
  </div>
</template>

<script>
import NewOffers from '@/components/Offers/NewOffers.vue'
export default {
  components: { NewOffers }
}
</script>
